<template>

  <div id="page-user-list">
    <new-student-modal :show="popupNewStudent" title="Novo Aluno" icon="warning"
      @action="deleteAccountPlan(node)"
      @cancel="popupNewStudent = false"></new-student-modal>

    <!-- <vx-card ref="filterCard" title="Filtros" class="user-list-filters mb-8" actionButtons @refresh="resetColFilters" @remove="resetColFilters">
      <div class="vx-row">
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Role</label>
          <v-select :options="roleOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="roleFilter" class="mb-4 md:mb-0" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Status</label>
          <v-select :options="statusOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="statusFilter" class="mb-4 md:mb-0" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Verified</label>
          <v-select :options="isVerifiedOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="isVerifiedFilter" class="mb-4 sm:mb-0" />
        </div>
        <div class="vx-col md:w-1/4 sm:w-1/2 w-full">
          <label class="text-sm opacity-75">Department</label>
          <v-select :options="departmentOptions" :clearable="false" :dir="$vs.rtl ? 'rtl' : 'ltr'" v-model="departmentFilter" />
        </div>
      </div>
    </vx-card> -->

    <div class="vx-card p-6">

      <div class="flex flex-wrap items-center">

        <!-- ITEMS PER PAGE -->
        <div class="flex-grow">
          <vs-dropdown vs-trigger-click class="cursor-pointer">
            <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
              <span class="mr-2">{{ currentPage * paginationPageSize - (paginationPageSize - 1) }} - {{ studentsData.total - currentPage * paginationPageSize > 0 ? currentPage * paginationPageSize : studentsData.total }} de {{ studentsData.total }}</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>
            <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
            <vs-dropdown-menu>
              <vs-dropdown-item @click="paginationSetSize(10)">
                <span>10</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetSize(20)">
                <span>20</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetSize(30)">
                <span>30</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetSize(50)">
                <span>50</span>
              </vs-dropdown-item>
              <vs-dropdown-item @click="paginationSetSize(100)">
                <span>100</span>
              </vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>

        <!-- TABLE ACTION COL-2: SEARCH & EXPORT AS CSV -->
          <vs-input class="sm:mr-4 mr-0 sm:w-auto w-full sm:order-normal order-3 sm:mt-0 mt-4" v-model="searchQuery" @input="updateSearchQuery" placeholder="Busca rápida por nome..." />
          <!-- <vs-button class="mb-4 md:mb-0" @click="gridApi.exportDataAsCsv()">Export as CSV</vs-button> -->

            <vs-button @click="handleNewStudent" icon-pack="feather" icon="icon-user-plus" class="shadow-md mb-4 md:mb-0">Novo Aluno</vs-button>

          <!-- ACTION - DROPDOWN -->
          <!-- <vs-dropdown vs-trigger-click class="cursor-pointer">

            <div class="p-3 shadow-drop rounded-lg d-theme-dark-light-bg cursor-pointer flex items-end justify-center text-lg font-medium w-32">
              <span class="mr-2 leading-none">Ações</span>
              <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
            </div>

            <vs-dropdown-menu>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="TrashIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Deletar</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="ArchiveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Arquivo</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="FileIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>Imprimir</span>
                </span>
              </vs-dropdown-item>

              <vs-dropdown-item>
                <span class="flex items-center">
                  <feather-icon icon="SaveIcon" svgClasses="h-4 w-4" class="mr-2" />
                  <span>CSV</span>
                </span>
              </vs-dropdown-item>

            </vs-dropdown-menu>
          </vs-dropdown> -->
      </div>

      <!-- AgGrid Table -->
      <ag-grid-vue
        ref="agGridTable"
        :components="components"
        :gridOptions="gridOptions"
        @grid-ready="onGridReady"
        class="ag-theme-material w-100 my-4 ag-grid-table"
        :columnDefs="columnDefs"
        :defaultColDef="defaultColDef"
        :overlayLoadingTemplate="overlayLoadingTemplate"
        :overlayNoRowsTemplate="noRowsTemplate"
        :localeText="localeText"
        rowSelection="multiple"
        colResizeDefault="shift"
        :animateRows="true"
        :pagination="true"
        :paginationPageSize="paginationPageSize"
        :cacheBlockSize="40"
        :suppressPaginationPanel="true"
        :enableRtl="$vs.rtl">
      </ag-grid-vue>

      <vs-pagination :total="totalPages" :max="10" v-model="currentPage" />

    </div>
  </div>

</template>

<script>
import { AgGridVue } from 'ag-grid-vue'
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
// import vSelect from 'vue-select'
import _ from 'lodash'
import { localePt_Br } from '../../../../assets/agGridTranslate/locale.pt_Br'
// Store Module
import moduleStudentManagement from '@/store/student-management/moduleStudentManagement.js'

// Cell Renderer
import CellRendererLink from './cell-renderer/CellRendererLink.vue'
import CellRendererPhones from './cell-renderer/CellRendererPhones.vue'
import CellRendererActions from './cell-renderer/CellRendererActions.vue'

const newStudentModal = () => import('./newStudentModal.vue')

import PaginationMixin from '@/mixins/pagination'

export default {
  mixins: [PaginationMixin],
  components: {
    AgGridVue,
    // vSelect,

    // Cell Renderer
    CellRendererLink,
    CellRendererPhones,
    CellRendererActions,
    newStudentModal
  },
  data () {
    return {
      popupNewStudent: false,
      localeText: null,
      // Filter Options
      // roleFilter: { label: 'Todos', value: 'all' },
      // roleOptions: [
      //   { label: 'Todos', value: 'all' },
      //   { label: 'Admin', value: 'admin' },
      //   { label: 'User', value: 'user' },
      //   { label: 'Staff', value: 'staff' }
      // ],

      // statusFilter: { label: 'Todos', value: 'all' },
      // statusOptions: [
      //   { label: 'Todos', value: 'all' },
      //   { label: 'Ativo', value: 'active' },
      //   { label: 'Inativo', value: 'deactivated' },
      //   { label: 'Bloqueado', value: 'blocked' }
      // ],

      // isVerifiedFilter: { label: 'Todos', value: 'all' },
      // isVerifiedOptions: [
      //   { label: 'Todos', value: 'all' },
      //   { label: 'Sim', value: 'yes' },
      //   { label: 'Não', value: 'no' }
      // ],

      // departmentFilter: { label: 'Todos', value: 'all' },
      // departmentOptions: [
      //   { label: 'Todos', value: 'all' },
      //   { label: 'Vendas', value: 'sales' },
      //   { label: 'Desenvolvimento', value: 'development' },
      //   { label: 'Gerência', value: 'management' }
      // ],

      searchQuery: '',

      // AgGrid
      gridApi: null,
      dataSource: {},
      gridOptions: {
        rowModelType: 'infinite'
      },
      overlayLoadingTemplate: 'Consultando dados...',
      noRowsTemplate: 'Nenhum dado foi encontrado',
      defaultColDef: {
        sortable: true,
        resizable: true,
        suppressMenu: true
      },
      columnDefs: [
        // {
        //   headerName: 'CÓDIGO',
        //   field: 'id',
        //   width: 140,
        //   floatingFilter:true,
        //   filter: 'agNumberColumnFilter',
        //   filterParams: {
        //     suppressAndOrCondition: true,
        //     filterOptions: ['startsWith'],
        //     defaultOption: 'startsWith',
        //     debounceMs: 800
        //   }
        //   // checkboxSelection: true,
        //   // headerCheckboxSelectionFilteredOnly: true,
        //   // headerCheckboxSelection: true
        // },
        {
          headerName: 'Nome',
          field: 'name',
          width: 350,
          floatingFilter:true,
          filter: true,
          filterParams: {
            suppressAndOrCondition: true,
            filterOptions: ['startsWith'],
            defaultOption: 'startsWith',
            debounceMs: 1200
          },
          cellRendererFramework: 'CellRendererLink'
        },
        {
          headerName: 'CPF',
          field: 'cpf',
          width: 190,
          floatingFilter:true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            suppressAndOrCondition: true,
            filterOptions: ['startsWith'],
            defaultOption: 'startsWith',
            numberParser: (text) => {
              return text === null ? null : text.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/,
                (regex, a1, a2, a3, a4) => `${a1}.${a2}.${a3}-${a4}`)
            },
            debounceMs: 1300
          }
        },
        {
          headerName: 'Telefones',
          field: 'phones',
          width: 290,
          floatingFilter:true,
          filter: 'agNumberColumnFilter',
          filterParams: {
            suppressAndOrCondition: true,
            filterOptions: ['startsWith'],
            defaultOption: 'startsWith',
            debounceMs: 800
          },
          sortable: false,
          cellRendererFramework: 'CellRendererPhones'
        },
        {
          headerName: 'Renach',
          field: 'renach_form_number',
          width: 180,
          floatingFilter:true,
          filter: true,
          filterParams: {
            suppressAndOrCondition: true,
            filterOptions: ['startsWith'],
            defaultOption: 'startsWith',
            debounceMs: 800
          }
        },
        {
          headerName: 'Ações',
          field: 'transactions',
          width: 95,
          sortable: false,
          cellRendererFramework: 'CellRendererActions'
          // cellRendererParams : {
          //   setDatasource : this.setDatasource.bind(this) // usado para passar parametros para dentro o componente renderizado no ag grid
          // }
        }
      ],

      components: {
        CellRendererLink,
        CellRendererPhones,
        CellRendererActions
      },

      payload: {
        offset: 0,
        limit: 40,
        searchQuery: null,
        params: null
      },
    }
  },
  watch: {
    // roleFilter (obj) {
    //   this.setColumnFilter('id', obj.value)
    // },
    // statusFilter (obj) {
    //   this.setColumnFilter('name', obj.value)
    // },
    // isVerifiedFilter (obj) {
    //   const val = obj.value === 'all' ? 'all' : obj.value === 'yes' ? 'true' : 'false'
    //   this.setColumnFilter('name', val)
    // },
    // departmentFilter (obj) {
    //   this.setColumnFilter('cpf', obj.value)
    // }
  },
  computed: {
    studentsData () {
      const students = this.$store.state.studentManagement.students
      return students
    },
  },
  methods: {
    handleNewStudent () {
      this.popupNewStudent = true
    },
    // setDatasource () { //usado para passar parametros para dentro o componente renderizado no ag grid
    // this.gridApi.setDatasource(this.dataSource)
    // this.gridApi.setRowData(this.studentsData)
    // this.gridApi.refreshInfiniteCache();
    // this.gridApi.refreshCells()
    // },
    getSearchResult (searchVal) {
      this.payload.searchQuery = searchVal
      this.gridApi.setDatasource(this.dataSource)
    },
    // setColumnFilter (column, val) {
    //   const filter = this.gridApi.getFilterInstance(column)
    //   let modelObj = null

    //   if (val !== 'all') {
    //     modelObj = { type: 'equals', filter: val }
    //   }

    //   filter.setModel(modelObj)
    //   this.gridApi.onFilterChanged()
    // },
    // resetColFilters () {
    // Reset Grid Filter
    // this.gridApi.setFilterModel(null)
    // this.gridApi.onFilterChanged()

    // Reset Filter Options
    // this.roleFilter = this.statusFilter = this.isVerifiedFilter = this.departmentFilter = { label: 'Todos', value: 'all' }

    // this.$refs.filterCard.removeRefreshAnimation()
    // },
    updateSearchQuery (val) {
      this.debouncedGetSearchResult(val)
      // this.gridApi.setQuickFilter(val)
    },

    onGridReady () {
      const self = this
      this.dataSource = {
        rowCount: null,
        async getRows (params) {
          self.payload.offset = params.startRow
          self.payload.params = params
          await self.getAllStudents() //AQUI EU TENHO O PAYLOAD E POSSO FAZER A CONSULTA, AO MESMO TEMPO DEVO AGUARDARA RESPOSTA PARA CONTINUAR
          let lastRow = -1
          if (self.studentsData.total <= params.endRow) {
            lastRow = self.studentsData.total
          }
          params.successCallback(self.studentsData.data, lastRow)
        }
      }
      this.gridApi.setDatasource(this.dataSource)
    },

    async getAllStudents () {
      this.gridApi.showLoadingOverlay()
      const students =  await this.$store.dispatch('studentManagement/fetchStudents', this.payload).catch(err => { console.error(err) })
      this.gridApi.hideOverlay()
      if (!students.data.total) {
        this.gridApi.showNoRowsOverlay()
      }
      return students
    },
  },
  mounted () {
    this.gridApi = this.gridOptions.api
    this.localeText = localePt_Br
    /* =================================================================
      NOTE:
      Header is not aligned properly in RTL version of agGrid table.
      However, we given fix to this issue. If you want more robust solution please contact them at gitHub
    ================================================================= */
    if (this.$vs.rtl) {
      const header = this.$refs.agGridTable.$el.querySelector('.ag-header-container')
      header.style.left = `-${  String(Number(header.style.transform.slice(11, -3)) + 9)  }px`
    }
  },
  created () {
    this.setGrid('studentsData')
    if (!moduleStudentManagement.isRegistered) {
      this.$store.registerModule('studentManagement', moduleStudentManagement)
      moduleStudentManagement.isRegistered = true
    }
    this.debouncedGetSearchResult = _.debounce(this.getSearchResult, 600)
  }
}

</script>

<style lang="scss">
#page-user-list {
  .user-list-filters {
    .vs__actions {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-58%);
    }
  }
}
</style>
