<template>
  <div v-if="params.data" class="flex items-center">
    <vs-avatar :src="params.data.photo && params.data.photo.url" class="flex-shrink-0 mr-2" size="30px" @click="$router.push(url)" />
    <feather-icon v-if="params.data.defaulting" title="Inadimplente" icon="AlertCircleIcon" svgClasses="h-4 w-4" class="ml-1 mr-1 text-danger" />
    <router-link :to="url" @click.stop.prevent class="text-inherit hover:text-primary">{{ params.value }}</router-link>
  </div>
</template>

<script>
export default {
  name: 'CellRendererLink',
  computed: {
    url () {
      if (this.params.data) {
        return `/student/student-edit/${this.params.data.id}`
      }
    }
  }
}
</script>
